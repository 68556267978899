<template>
  <div class="cottonBaleDetails">
    <basic-container>
      <div>
        <div class="layTop">
          <div class="Title" style="margin-bottom:10px;">{{ $t('COTTON BALE TRACING') }}</div>
        </div>
        <template>
          <el-row>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" style="margin-bottom:20px;">
<!--              <el-autocomplete v-model="SeaCodeVal" :placeholder="$t('Bale IDs')"
                               prefix-icon="el-icon-help"
                               style="width:300px;" size="small" :fetch-suggestions="querySearchCodeAsync"
                               @select="handleSelect" @change="handleChange">
              </el-autocomplete>-->
              <el-select v-model="value" clearable @change="valueChage(value)" filterable :placeholder="$t('please choose bale id')"  style="width: calc(100% - 155px);"  >
                <el-option
                    v-for="(item, index) in BaleInfo"
                    :key="index"
                    :label="item.BaleId"
                    :value="item.BaleId">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="12" :md="24" :lg="24" :xl="24"
                    style="margin-bottom:20px;background:#F5FAFF;padding: 29px 49px">
              <el-col :span="8">
                <div class="grid-content bg-purple">
                  <div>
                    <p>{{ $t('Picking Number') }}: {{ BaleTracingData.PickingNumber }}</p>
                  </div>
                  <div>
                    <p>{{ $t('Yarn Supplier') }}: {{ BaleTracingData.YarnSupplier }}</p>
                  </div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="grid-content bg-purple">
                  <div>
                    <p>{{ $t('PICKING DATE') }}: {{ BaleTracingData.PickingDate }}</p>
                  </div>
                  <div>
                    <p>{{ $t('BALE COUNT') }}: {{ BaleTracingData.BaleCount }}</p>
                  </div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="grid-content bg-purple">
                  <div>
                    <p>{{ $t('QUANTITY') }}: {{ BaleTracingData.Quantity }}</p>
                  </div>
                  <div>
                    <p>{{ $t('YARN BATCH COUNT') }}:{{ BaleTracingData.YarnBatchCount }}</p>
                  </div>
                </div>
              </el-col>
            </el-col>
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom:20px;">
              <div class="table-box">
                <div class="topTitleCon"><i style="color:#4D8AF0" class="iconfont icon-yuan"></i>{{ $t('Bale IDs') }}
                </div>
                <el-table
                    :data="BaleInfoData"  :cell-style="tableCellStyle"
                    style="width: 100%;  margin-top: 8px;">
                  <el-table-column
                      label="#"
                      type="index"
                      width="">
                  </el-table-column>
                  <el-table-column
                      prop=""
                      :label="$t('Bale ID')"
                      width="">
                    <template slot-scope="scope"><p>{{scope.row.BaleId}}</p></template>
                  </el-table-column>
                  <el-table-column
                      prop="RawMaterialName"
                      :label="$t('Raw material name')"
                      width="">
                  </el-table-column>
                  <el-table-column
                      prop="RawMaterialBatchNumbe"
                      :label="$t('Raw material batch number')">
                  </el-table-column>
                </el-table>
               <div style="display:flex;justify-content: space-between;margin-top: 10px;" v-if="BaleInfoData.length == 10">
                 <div></div>
                 <el-pagination
                     background
                     layout="prev, pager, next"
                     @current-change="handleCurrentChange"
                     :page-size="10"
                     :total="BaleInfo.length">
                 </el-pagination>
               </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom:20px;">
              <div class="table-box">
                <div class="topTitleCon"><i style="color:#4D8AF0" class="iconfont icon-yuan"></i>{{ $t('Yarn POs') }}
                </div>
                <el-table
                    :data="BaleTracingData.YarnPoInfo"
                    style="width: 100%;  margin-top: 8px;">
                  <el-table-column
                      prop="BusinessNoIn"
                      :label="$t('Tracing NO.')"
                      width="">
                    <!--<template slot-scope="scope">
                      <p @click="orderFracing(scope.row)" class="el-link--primary">{{scope.row.BusinessNoIn}}</p>
                    </template>-->
                  </el-table-column>
                  <el-table-column
                      prop="Po"
                      :label="$t('PO')"
                      width="">
                  </el-table-column>
                  <el-table-column
                      prop="VendorCorporationName"
                      :label="$t('Supplier')">
                  </el-table-column>
                  <el-table-column
                      prop="Code"
                      :label="$t('Product number')">
                  </el-table-column>
                  <el-table-column
                      prop="ProductName"
                      :label="$t('Product name')">
                  </el-table-column>
                  <el-table-column
                      prop="Color"
                      :label="$t('Batch')">
                  </el-table-column>
                </el-table>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom:20px;">
              <div class="table-box">
                <div class="topTitleCon"><i style="color:#4D8AF0" class="iconfont icon-yuan"></i>{{ $t('Fabric POs') }}
                </div>
                <el-table
                    :data="BaleTracingData.FabricPoInfo"
                    style="width: 100%;  margin-top: 8px;">
                  <el-table-column
                      prop="BusinessNoIn"
                      :label="$t('Tracing NO.')"
                      width="">
                    <!--<template slot-scope="scope">
                      <p @click="orderFracing(scope.row)" class="el-link--primary">{{scope.row.BusinessNoIn}}</p>
                    </template>-->
                  </el-table-column>
                  <el-table-column
                      prop="Po"
                      :label="$t('PO')"
                      width="">
                  </el-table-column>
                  <el-table-column
                      prop="VendorCorporationName"
                      :label="$t('Supplier')">
                  </el-table-column>
                  <el-table-column
                      prop="Code"
                      :label="$t('Product number')">
                  </el-table-column>
                  <el-table-column
                      prop="ProductName"
                      :label="$t('Color')">
                  </el-table-column>
                  <el-table-column
                      prop="Color"
                      :label="$t('Batch')">
                  </el-table-column>
                </el-table>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom:20px;">
              <div class="table-box">
                <div class="topTitleCon"><i style="color:#4D8AF0" class="iconfont icon-yuan"></i>{{ $t('Garment POs') }}
                </div>
                <el-table
                    :data="BaleTracingData.GarmentPoInfo"
                    style="width: 100%;  margin-top: 8px;">
                  <el-table-column
                      prop=""
                      :label="$t('Tracing NO.')"
                      width="">
                    <template slot-scope="scope">
                      <p @click="orderFracing(scope.row)" class="el-link--primary">{{scope.row.BusinessNoIn}}</p>
                    </template>
                  </el-table-column>
                  <el-table-column
                      prop="Po"
                      :label="$t('PO')"
                      width="">
                  </el-table-column>
                  <el-table-column
                      prop="VendorCorporationName"
                      :label="$t('Supplier')">
                  </el-table-column>
                  <el-table-column
                      prop="Code"
                      :label="$t('Style')">
                  </el-table-column>
                  <el-table-column
                      prop="ProductName"
                      :label="$t('Descript')">
                  </el-table-column>
                  <el-table-column
                      prop="Color"
                      :label="$t('Color')">
                  </el-table-column>
                </el-table>
              </div>
            </el-col>
          </el-row>
        </template>
      </div>
    </basic-container>
  </div>
</template>

<script>
import { BaleTracing } from '@/api/maintain'
export default {
  name: "cottonBaleDetails",
  data() {
    return {
        value: '',
        isSelect: false,
      SeaCodeVal: '',
      tableData: [],
      BaleInfo: [],
      BaleInfoData: [],
      newBaleInfoData: [],
      BaleTracingData: {}
    }
  },
  created() {
    this.list();
  },
        methods: {
            tableCellStyle({ row, column, rowIndex, columnIndex }) {
                let rowBackground = {};
                if (columnIndex == 1 && this.isSelect) {
                    rowBackground.background = "#FFE4E1";
                }
                else
                {
                    rowBackground.background = "#fff";
                }
                return rowBackground;
            },
    valueChage(value) {
      var data = [];
      this.BaleInfo.forEach((f) => {
        if (value == f.BaleId) {
          data.push(f)
        }
      });
        console.log("this.isSelect", this.isSelect, value);
        if (value == "") {
            this.isSelect = false;
        }
        else {
            this.isSelect = true;
        }
      this.BaleInfoData = [];
      this.BaleInfoData = data
    },
    orderFracing(row) {
      this.$router.push({ path: '/pvh_operation/orderFracing', query: { ID: row.Id , TracingNo: row.BusinessNoIn } })
    },
    querySearchCodeAsync(queryString, cb) {
      var Coderestaurants = this.Coderestaurants;
      var results = queryString ? Coderestaurants.filter(this.createStateFilterCode(queryString)) : Coderestaurants;
      clearTimeout(this.Codetimeout);
      this.Codetimeout = setTimeout(() => {
        cb(results.slice(0, 10));
      }, 1000 * Math.random());
    },
    handleChange() {
      this.getPurOrdList();
    },
    handleSelect() {
      this.getPurOrdList();
    },//选中批号
    handleCurrentChange(val) {
        this.BaleInfoData = [];
      this.BaleInfo.forEach((f, index) => {
        if (val*10-11 < index && val*10 > index) {
          this.BaleInfoData.push(f)
        }
      })
    },
    list() {

      var data = this.$route.query;
      BaleTracing(data.corporationId, data.pickingNo).then((response) => {
        response.data.YarnPoInfo.forEach((f) => {
          f.BusinessNoIn = f.PoRowDetailInfo.BusinessNoIn
          f.Id = f.PoRowDetailInfo.Id;
          f.Po = f.PoRowInfo.Po;
          f.VendorCorporationName = f.PoRowInfo.VendorCorporationName
          f.Code = f.PoRowDetailInfo.Code
          f.Color = f.PoRowDetailInfo.Color
        })
        response.data.FabricPoInfo.forEach((f) => {
          f.BusinessNoIn = f.PoRowDetailInfo.BusinessNoIn
          f.Id = f.PoRowDetailInfo.Id;
          f.Po = f.PoRowInfo.Po;
          f.VendorCorporationName = f.PoRowInfo.VendorCorporationName
          f.Code = f.PoRowDetailInfo.Code
          f.Batch = f.PoRowDetailInfo.Batch
          f.Color = f.PoRowDetailInfo.Color
        })
        response.data.GarmentPoInfo.forEach((f) => {
          f.BusinessNoIn = f.PoRowDetailInfo.BusinessNoIn
          f.Id = f.PoRowDetailInfo.Id;
          f.Po = f.PoRowInfo.Po;
          f.VendorCorporationName = f.PoRowInfo.VendorCorporationName
          f.Code = f.PoRowDetailInfo.Code
          f.Color = f.PoRowDetailInfo.Color
        })
        this.BaleTracingData = response.data;
        this.BaleInfo = response.data.BaleInfo;
        this.BaleInfo.forEach((f, index) => {
          if (index < 10) {
            this.BaleInfoData.push(f)
          }
        })
      })
    }
  },
  watch:{
    value(New) {
      console.log(New)
      if (New == '') {
        this.handleCurrentChange(1)
      }
    }
  }
}
</script>

<style scoped lang="scss">
    .el-table .warning-row {
        background: oldlace;
    }
.bg-purple {
  p {
    margin-bottom: 9px;
    font-size: 0.65rem;
    color: #303133;
  }
}

.table-box {
  border: 1px dashed #979797;
  padding: 14px;
  border-radius: 10px;

  .topTitleCon {
    font-size: 16px;
    color: #333;
    box-sizing: border-box;
    font-weight: 700;
  }

  .topTitleCon i {
    vertical-align: 1px;
    font-size: 13px !important;
    margin-right: 15px;
    -webkit-font-smoothing: antialiased;
  }
  .el-link--primary {
    color: #409eff;
    cursor: pointer;
  }
}
</style>
