var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cottonBaleDetails" },
    [
      _c("basic-container", [
        _c(
          "div",
          [
            _c("div", { staticClass: "layTop" }, [
              _c(
                "div",
                {
                  staticClass: "Title",
                  staticStyle: { "margin-bottom": "10px" }
                },
                [_vm._v(_vm._s(_vm.$t("COTTON BALE TRACING")))]
              )
            ]),
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "calc(100% - 155px)" },
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: _vm.$t("please choose bale id")
                          },
                          on: {
                            change: function($event) {
                              return _vm.valueChage(_vm.value)
                            }
                          },
                          model: {
                            value: _vm.value,
                            callback: function($$v) {
                              _vm.value = $$v
                            },
                            expression: "value"
                          }
                        },
                        _vm._l(_vm.BaleInfo, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.BaleId, value: item.BaleId }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        "margin-bottom": "20px",
                        background: "#F5FAFF",
                        padding: "29px 49px"
                      },
                      attrs: { xs: 24, sm: 12, md: 24, lg: 24, xl: 24 }
                    },
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "grid-content bg-purple" }, [
                          _c("div", [
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("Picking Number")) +
                                  ": " +
                                  _vm._s(_vm.BaleTracingData.PickingNumber)
                              )
                            ])
                          ]),
                          _c("div", [
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("Yarn Supplier")) +
                                  ": " +
                                  _vm._s(_vm.BaleTracingData.YarnSupplier)
                              )
                            ])
                          ])
                        ])
                      ]),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "grid-content bg-purple" }, [
                          _c("div", [
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("PICKING DATE")) +
                                  ": " +
                                  _vm._s(_vm.BaleTracingData.PickingDate)
                              )
                            ])
                          ]),
                          _c("div", [
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("BALE COUNT")) +
                                  ": " +
                                  _vm._s(_vm.BaleTracingData.BaleCount)
                              )
                            ])
                          ])
                        ])
                      ]),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "grid-content bg-purple" }, [
                          _c("div", [
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("QUANTITY")) +
                                  ": " +
                                  _vm._s(_vm.BaleTracingData.Quantity)
                              )
                            ])
                          ]),
                          _c("div", [
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("YARN BATCH COUNT")) +
                                  ":" +
                                  _vm._s(_vm.BaleTracingData.YarnBatchCount)
                              )
                            ])
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "table-box" },
                        [
                          _c("div", { staticClass: "topTitleCon" }, [
                            _c("i", {
                              staticClass: "iconfont icon-yuan",
                              staticStyle: { color: "#4D8AF0" }
                            }),
                            _vm._v(
                              _vm._s(_vm.$t("Bale IDs")) + "\n                "
                            )
                          ]),
                          _c(
                            "el-table",
                            {
                              staticStyle: {
                                width: "100%",
                                "margin-top": "8px"
                              },
                              attrs: {
                                data: _vm.BaleInfoData,
                                "cell-style": _vm.tableCellStyle
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: { label: "#", type: "index", width: "" }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "",
                                  label: _vm.$t("Bale ID"),
                                  width: ""
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c("p", [
                                          _vm._v(_vm._s(scope.row.BaleId))
                                        ])
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "RawMaterialName",
                                  label: _vm.$t("Raw material name"),
                                  width: ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "RawMaterialBatchNumbe",
                                  label: _vm.$t("Raw material batch number")
                                }
                              })
                            ],
                            1
                          ),
                          _vm.BaleInfoData.length == 10
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "space-between",
                                    "margin-top": "10px"
                                  }
                                },
                                [
                                  _c("div"),
                                  _c("el-pagination", {
                                    attrs: {
                                      background: "",
                                      layout: "prev, pager, next",
                                      "page-size": 10,
                                      total: _vm.BaleInfo.length
                                    },
                                    on: {
                                      "current-change": _vm.handleCurrentChange
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "table-box" },
                        [
                          _c("div", { staticClass: "topTitleCon" }, [
                            _c("i", {
                              staticClass: "iconfont icon-yuan",
                              staticStyle: { color: "#4D8AF0" }
                            }),
                            _vm._v(
                              _vm._s(_vm.$t("Yarn POs")) + "\n                "
                            )
                          ]),
                          _c(
                            "el-table",
                            {
                              staticStyle: {
                                width: "100%",
                                "margin-top": "8px"
                              },
                              attrs: { data: _vm.BaleTracingData.YarnPoInfo }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "BusinessNoIn",
                                  label: _vm.$t("Tracing NO."),
                                  width: ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "Po",
                                  label: _vm.$t("PO"),
                                  width: ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "VendorCorporationName",
                                  label: _vm.$t("Supplier")
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "Code",
                                  label: _vm.$t("Product number")
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "ProductName",
                                  label: _vm.$t("Product name")
                                }
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "Color", label: _vm.$t("Batch") }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "table-box" },
                        [
                          _c("div", { staticClass: "topTitleCon" }, [
                            _c("i", {
                              staticClass: "iconfont icon-yuan",
                              staticStyle: { color: "#4D8AF0" }
                            }),
                            _vm._v(
                              _vm._s(_vm.$t("Fabric POs")) +
                                "\n                "
                            )
                          ]),
                          _c(
                            "el-table",
                            {
                              staticStyle: {
                                width: "100%",
                                "margin-top": "8px"
                              },
                              attrs: { data: _vm.BaleTracingData.FabricPoInfo }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "BusinessNoIn",
                                  label: _vm.$t("Tracing NO."),
                                  width: ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "Po",
                                  label: _vm.$t("PO"),
                                  width: ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "VendorCorporationName",
                                  label: _vm.$t("Supplier")
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "Code",
                                  label: _vm.$t("Product number")
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "ProductName",
                                  label: _vm.$t("Color")
                                }
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "Color", label: _vm.$t("Batch") }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "table-box" },
                        [
                          _c("div", { staticClass: "topTitleCon" }, [
                            _c("i", {
                              staticClass: "iconfont icon-yuan",
                              staticStyle: { color: "#4D8AF0" }
                            }),
                            _vm._v(
                              _vm._s(_vm.$t("Garment POs")) +
                                "\n                "
                            )
                          ]),
                          _c(
                            "el-table",
                            {
                              staticStyle: {
                                width: "100%",
                                "margin-top": "8px"
                              },
                              attrs: { data: _vm.BaleTracingData.GarmentPoInfo }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "",
                                  label: _vm.$t("Tracing NO."),
                                  width: ""
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "el-link--primary",
                                            on: {
                                              click: function($event) {
                                                return _vm.orderFracing(
                                                  scope.row
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.BusinessNoIn)
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "Po",
                                  label: _vm.$t("PO"),
                                  width: ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "VendorCorporationName",
                                  label: _vm.$t("Supplier")
                                }
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "Code", label: _vm.$t("Style") }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "ProductName",
                                  label: _vm.$t("Descript")
                                }
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "Color", label: _vm.$t("Color") }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ]
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }